<template>
  <div>
    <div class="m-5 bg-white">
      <div class="border border-gray-400">
        <div class="p-4 flex justify-between items-center">
          <span class="font-bold text-rose">
            {{ $t('reports_nurse_title') }}
          </span>
          <BackFa :text="$t('back')" />
        </div>
        <hr class="w-full h-1 mx-auto my-3" />
        <div class="w-1/2 mx-auto flex justify-between text-center">
          <span class="w-20">{{ $t('date_reports') }} </span>
          <span class="w-20 mx-20">{{ $t('login_time2') }}</span>
          <span class="w-20">{{ $t('logout') }}</span>
        </div>
        <div class="mt-3 w-full bg-primary text-white">
          <div class="w-1/2 mx-auto flex justify-between text-center">
            <span class="w-20">
              {{ convertDate(null, isRtl ? 'DD-MM-YYYY' : 'YYYY-MM-DD') }}
            </span>
            <span class="w-20"> {{ convertDate(null, 'HH:m') }}</span>
            <span class="w-20"> {{ convertDate(null, 'HH:m') }}</span>
          </div>
        </div>
        <BaseTable
          class="mb-6"
          header-class="bg-gray-50 text-gray-400 text-sm"
          :headers="headers"
          :items="items"
          theme-type="even"
        >
          <template #head:text="{ head }">
            <span> {{ head.text }}</span>
          </template>
          <template #item:chack="{ item }">
            <div class="flex items-start">
              <div
                :class="{
                  'ml-3': isRtl,
                  'mr-3': isLtr,
                  'bg-rose': item.status === 'PROBLEM',
                  'bg-gray-300': item.status !== 'PROBLEM',
                }"
                class="rounded-full h-4 w-4"
              ></div>
              <!-- <input
                :class="{ 'ml-5': isRtl, 'mr-5': isLtr }"
                type="radio"
                class="form-checkbox mt-2 h-4 w-4"
                :checked="item.selected"
              /> -->
              <div class="font-extralight">
                <h3 class="text-base">{{ item.chack }}</h3>
              </div>
            </div>
          </template>

          <template #item:status="{ item }">
            <button
              @click="goto(item.to)"
              :class="{
                'bg-rose': item.status === 'PROBLEM',
                'bg-cyan-400': item.status === 'ok',
              }"
              class="text-white px-6 py-1 rounded-2xl shadow-lg"
            >
              {{ item.status }}
            </button>
          </template>
          <template #item:subject="{ item }">
            <div
              :class="{
                'bg-rose': item.status === 'PROBLEM',
                'bg-cyan-400': item.status === 'ok',
              }"
              class="h-3 w-3 rounded-full"
            ></div>
          </template>
        </BaseTable>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'ReportsNurse2',
  components: { BaseTable, BackFa },
  computed: {
    headers() {
      return [
        {
          key: 'chack',
          text: `${this.$t('doctor_reports')}/${this.$t('specialty_reports')}`,
        },
        {
          key: 'status',
          text: this.$t('doing'),
        },
        {
          key: 'subject',
          text: this.$t('list_medicines_description'),
        },
      ]
    },
    items() {
      return [
        {
          chack: this.$t('chack'),
          status: 'ok',
          subject: '',
        },
        {
          chack: this.$t('chack1'),
          status: 'PROBLEM',
          subject: '',
          to: { name: 'Medicines' },
        },
        {
          chack: this.$t('chack2'),
          status: 'ok',
          subject: '',
        },
        {
          chack: this.$t('chack3'),
          status: 'ok',
          subject: '',
        },
      ]
    },
  },
  data() {
    return {
      ShowInformation: false,
    }
  },
  methods: {
    goto(to) {
      !!to && this.$router.push(to)
    },
  },
}
</script>

<style></style>
